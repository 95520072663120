<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            Total Suppliers
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ totalUsers }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            Total Active
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ helpers.nairaSign() }}100,002
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            Total Disabled
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ helpers.formatAmount(totalUsersBalance) }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            Total Balances
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ helpers.formatAmount(totalAdminBalance) }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <suppliers-table></suppliers-table>
  </div>
</template>

<script>
import suppliersTable from "@/components/suppliers/suppliersTable.vue";
import Helpers from "@/utils/helpers";
export default {
  name: "DashboardPage",
  components: {
    suppliersTable,
  },
  layout: "authenticated",
  data() {
    return {
      helpers: new Helpers(),
      totalUsers: 0,
      totalUsersBalance: 0,
      totalAdminBalance: 0,
    };
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style scoped></style>
